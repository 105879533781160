@import "../../assets/styles/main.module";

.cardTracking {
  box-shadow: $boxShadow;
  border-radius: 16px;
  overflow: hidden;
  &.arrived {
    .container {
      border-color: color(green);
      .header {
        h5 {
          background: color(green);
        }
      }
    }
  }
  .container {
    padding: 20px 25px;
    border-left: 5px solid color(greyPurple);
    .header {
      display: flex;
      justify-content: space-between;
      h4 {
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        flex: 1;
        margin: 0 20px 0 0;
        @include media(tablet) {
          font-size: 16px;
          line-height: 18px;
        }
      }
      h5 {
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        margin: 0;
        padding: 2px 5px;
        border-radius: 3px;
        color: color(white);
        background: color(greyPurple);
        @include media(tablet) {
          font-size: 14px;
          line-height: 16px;
          padding: 4px;
        }
      }
    }
    .info {
      display: flex;
      margin: 20px 0 0;
      .date,
      .eta {
        p {
          margin: 0;
          font-weight: 300;
          font-size: 12px;
          line-height: 17px;
          color: #9f9f9f;
          @include media(tablet) {
            font-size: 14px;
            line-height: 19px;
          }
          &:nth-child(2) {
            font-weight: 700;
            color: color(purple);
          }
        }
      }
      .eta {
        margin: 0 0 0 50px;
      }
    }
    .content,
    .tracking_number {
      margin: 20px 0 0;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      @include media(tablet) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .content {
      & > *:nth-child(1) {
        margin-top: 0;
      }
      & > p {
        margin: 10px 0 0;
      }
    }
    .tracking_number {
      // margin: 0;
    }
  }
}
