@import "../../assets/styles/main.module";

.footer {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  // margin: 50px 0 0;
  // padding: 0 0 20px;
  .social {
    display: flex;
    justify-content: center;
    align-items: center;
    .imgSocial {
      cursor: pointer;
      margin: 0 7px;
      width: 20px;
      height: 20px;
      @include imgCont;
    }
    p {
      font-size: 12px;
      line-height: 14px;
      margin: 0;
    }
  }
  .copy {
    margin: 15px 0 0;
    font-size: 10px;
    line-height: 12px;
    color: color(lightGrey);
    text-align: center;
  }
}
