@import "../../assets/styles/main.module";

.arrow {
  cursor: pointer;
  position: absolute !important;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(calc(100% + 10px));
  margin: auto;
  width: 48px;
  height: 48px;
  background: #ee575d;
  border-radius: 50%;
  @include imgCont;
  &.prev {
    right: unset;
    left: 0;
    transform: translateX(calc(-100% - 10px));
    img {
      transform: rotate(180deg);
    }
  }
  img {
    max-width: 50%;
    max-height: 50%;
  }
}
.sliderContainer {
  position: relative;
  overflow: hidden;
  .bg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -90.5%);
    width: 2500px;
    height: 2500px;
    border-radius: 50%;
    background: color(yellow);
  }
}
.sliderBanner {
  & > button {
    display: none !important;
  }
  .sliderItem {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    &.go {
      cursor: pointer;
    }
    &.active {
      display: flex;
    }
    .imgCont {
      width: 100%;
      position: relative;
      @include imgCont;
      padding-bottom: 78.34%;
      img {
        max-width: 80%;
        max-height: 80%;
      }
    }
    .overlay {
      @include overlay;
      z-index: 1;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 60%,
        rgba(0, 0, 0, 0.63)
      );
    }
    .content {
      margin: 20px 0 0;
      text-align: center;
      font-size: 20px;
      line-height: 22px;
      @include media(tablet) {
        font-size: 22px;
        line-height: 24px;
      }
      span {
        color: color(purple);
        font-weight: 900;
        margin-right: 5px;
        @include media(tablet) {
          margin-right: 10px;
        }
      }
    }
  }
  & div div div div {
    padding: 0px 5px;
  }
  & ul {
    position: relative;
    bottom: 0;
    margin: 20px auto 0;
    height: 20px;
    width: auto;
    text-align: left;
    display: flex;
    justify-content: center;
    @include media(miniDesktop) {
      margin: 15px auto 0;
    }
    li {
      width: auto;
      height: auto;
      transition: 0.5s;
      button {
        cursor: pointer;
        width: 10px;
        height: 10px;
        background: #e8e8e8;
        border-radius: 50%;
        transition: 0.5s;
        margin: 0 2px 0;
        // @include media(miniDesktop) {
        //   width: 15px;
        //   height: 15px;
        // }
        &.dotActive {
          background: color(yellow);
          width: 25px;
          border-radius: 5px;
        }
        &::before {
          display: none;
        }
      }
    }
  }
}

.skeleton {
  border-radius: 8px;
  overflow: hidden;
  width: calc(100% - 10px) !important;
  height: 165px !important;
  margin: auto !important;
}
