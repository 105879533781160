@mixin media($size) {
  @media screen and (min-width: mediaFunction($size)) {
    @content;
  }
}

@mixin mediaMin($size) {
  @media screen and (min-width: mediaFunction($size)) {
    @content;
  }
}

@mixin mediaMax($size) {
  @media screen and (max-width: mediaFunction($size - 1)) {
    @content;
  }
}

@mixin container {
  width: 100%;
  padding: 0 15px;
  max-width: 768px;
  margin: auto;
  @include media(tablet) {
    padding: 0 20px;
  }
}

@mixin customGrid($map) {
  width: calc((100% / #{map-get($map, "column")}) - #{map-get($map, "gutter")});
  margin: calc(
      #{map-get($map, "gutter")} + (#{map-get($map, "gutter")} / (#{map-get(
                  $map,
                  "column"
                )} - 1))
    )
    0 0
    calc(
      #{map-get($map, "gutter")} + (#{map-get($map, "gutter")} / (#{map-get(
                  $map,
                  "column"
                )} - 1))
    );
  &:nth-child(#{map-get($map, "column")}n + 1) {
    margin-left: 0;
  }
}

@mixin customGridImp($map) {
  width: calc(
    (100% / #{map-get($map, "column")}) - #{map-get($map, "gutter")}
  ) !important;
  margin: calc(
      #{map-get($map, "gutter")} + (#{map-get($map, "gutter")} / (#{map-get(
                  $map,
                  "column"
                )} - 1))
    )
    0 0
    calc(
      #{map-get($map, "gutter")} + (#{map-get($map, "gutter")} / (#{map-get(
                  $map,
                  "column"
                )} - 1))
    ) !important;
  &:nth-child(#{map-get($map, "column")}n + 1) {
    margin-left: 0 !important;
  }
}

@mixin unsetCustomGrid($map) {
  width: unset !important;
  margin: unset !important;
  &:nth-child(#{map-get($map, "column")}n + 1) {
    margin-left: unset !important;
  }
}
@mixin unsetCustomGridNot($map) {
  width: unset;
  margin: unset;
  &:nth-child(#{map-get($map, "column")}n + 1) {
    margin-left: unset;
  }
}

@mixin imgCont {
  position: relative;
  & > img {
    @include imgAbsolute();
  }
}

@mixin imgAbsolute {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

@mixin textOverflow($line) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  -webkit-box-pack: end;
}

@mixin overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
}

@mixin noScrollBar {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0px;
  }
}
