@import "../../assets/styles/main.module";

.notFound {
  padding-top: 20px;
  .container {
    .imgCont {
      width: 77px;
      margin: auto;
      img {
        max-width: 100%;
      }
    }
    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      margin: 20px 0 0;
    }
    p {
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      margin: 15px 0 0;
      color: color(lightBlack);
    }
  }
}
