@import "../../assets/styles/main.module";

.listTracking {
  padding: 0 20px;
  @include container;
  .info {
    display: flex;
    justify-content: space-between;
    .content {
      display: flex;
      align-items: center;
      .imgCont {
        width: 15px;
        height: 15px;
        @include imgCont;
        @include media(tablet) {
          width: 18px;
          height: 18px;
        }
      }
      p {
        margin: 0 0 0 5px;
        font-size: 14px;
        font-weight: 700;
        @include media(tablet) {
          font-size: 16px;
          line-height: 18px;
          margin-left: 10px;
        }
      }
    }
    .found {
      font-size: 14px;
      line-height: 16px;
      margin: 0;
      @include media(tablet) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
  .itemTrack {
    margin: 30px 0 0;
    &:nth-child(2) {
      margin: 25px 0 0;
    }
  }
}
