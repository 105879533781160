@import "../../assets/styles/main.module";

.container {
  max-width: 500px;
}

.slider {
  padding: 0 20px;
  max-width: 500px;
  margin: auto;
}

.loaderChase {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 25% 0;
  margin: auto;
  & > div {
    width: 50px !important;
    height: 50px !important;
  }
}
