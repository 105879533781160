@import "../../assets/styles/main.module";

.app {
  width: 100%;
  position: relative;
  // min-height: 100vh;
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .main {
    padding-top: 70px;
    padding-bottom: 107px;
    // @include media(miniDesktop) {
    //   padding-top: 103px;
    // }
  }
}
