@import "../../assets/styles/main.module";

.inputPlc {
  &.alignIconRight,
  &.alignIconLeft {
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
      flex: 1;
    }
  }
  &.alignIconLeft {
    .imgCont {
      order: 0;
      margin-left: 10px;
    }
    input {
      padding: 12px 12px 12px 0px;
      margin-left: 5px;
    }
  }
  &.alignIconRight {
    .imgCont {
      margin-right: 10px;
      order: 1;
    }
    input {
      padding: 12px 0px 12px 12px;
      margin-right: 5px;
    }
  }
  &.multiple {
    display: flex;
    align-items: center;
    .imgCont {
      margin-left: 10px;
    }
    input {
      order: 1;
      flex: 1;
      padding: 12px 0px;
      margin: 0px 5px;
    }
    .imgCont2 {
      order: 2;
      margin-right: 10px;
      margin-left: 0px;
    }
    .pointer {
      cursor: pointer;
    }
  }
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  input {
    width: 100%;
    background: transparent;
    padding: 12px 20px;
    font-size: 14px;
    font-family: font(primary);
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    outline: none;
    &::placeholder {
      color: color(lightGrey);
      font-size: 14px;
    }
    &:disabled {
      background: darken(color(white), 5%);
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: color(primary);
      -webkit-box-shadow: 0 0 0px 1000px #ededed inset;
      font-size: 14px;
    }
    &.password {
      border: none;
      border-radius: 0;
    }
  }

  .imgCont {
    position: relative;
    width: 24px;
    height: 24px;
    &.password {
      cursor: pointer;
    }
    img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
