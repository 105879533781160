@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400;1,600;1,700;1,900&display=swap");

@import "./assets/styles/main.module";

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  color: color(blackApp);
  font-family: "Nunito Sans", sans-serif, -apple-system;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
