@import "../../assets/styles/main.module";

.header {
  background: color(purple);
  padding: 20px 20px 0;
  border-radius: 0 0 16px 16px;
  @include media(tablet) {
    border-radius: 0 0 22px 22px;
  }
  .logo {
    margin: auto;
    width: 115px;
    img {
      display: block;
      width: 100%;
    }
  }
  .container {
    @include container;
    margin-top: 45px;
    h1,
    p {
      color: color(white);
    }
    h1 {
      font-weight: 900;
      margin: 0 auto;
      text-align: center;
      font-size: 24px;
      line-height: 28px;
      @include media(tablet) {
        font-size: 32px;
        line-height: 36px;
      }
    }
    p {
      font-weight: 300;
      margin: 12px 0 0;
      text-align: center;
      font-size: 12px;
      line-height: 14px;
      @include media(tablet) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
  .searchbar {
    transform: translateY(50%);
    border-radius: 16px;
    box-shadow: $boxShadow;
    & > div {
      &:nth-child(1) {
        margin-right: 20px;
        width: 32px;
        height: 32px;
        background: color(yellow);
        border-radius: 10px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
        & > img {
          max-width: 20px;
          max-height: 20px;
        }
      }
    }
    input {
      padding: 20px 25px !important;
      border: none;
      &::placeholder {
        font-style: italic;
      }
    }
  }
}
